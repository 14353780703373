import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import moment from "moment";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const EventCard = ({ event }) => {
	return (
		<Col style={{ overflow: "hidden" }} className="mb-5 pb-xl-0" md={6} xl={4}>
			<div style={{ overflow: "hidden" }} className=" w-100 h-100  ">
				<div>
					<BgImage
						style={{ height: "300px", backgroundPosition: "top" }}
						className="w-100 "
						image={getImage(
							event.eventFields.featuredImage?.localFile.childImageSharp
								.gatsbyImageData
						)}
						alt={event.title}
					></BgImage>
				</div>

				<div style={{ zIndex: 1 }} className="bg-light-blue p-4">
					<h2 className=" text-primary  fs-3  ">{event.eventFields.heading}</h2>

					<div style={{ height: "52px" }}>
						<p className="inter-bold">{event.eventFields.location}</p>
					</div>
					<p>
						{" "}
						{moment(
							new Date(event.eventFields.dateAndTime.replace(" ", "T"))
						).format("dddd DD MMMM")}
						{event.eventFields.extraDate === true && (
							<span>
								{" "}
								-{" "}
								{moment(
									new Date(event.eventFields.extraDateAndTime.replace(" ", "T"))
								).format("dddd DD MMMM")}
							</span>
						)}
					</p>

					<p>
						{" "}
						{moment(
							new Date(event.eventFields.dateAndTime.replace(" ", "T"))
						).format("HH:mm")}{" "}
						{event.eventFields.endTime && (
							<span>- {event.eventFields.endTime}</span>
						)}
					</p>
					<Link className="red-link py-4" to={`/events/${event.slug}`}>
						Read More
					</Link>
				</div>
			</div>
		</Col>
	);
};

export default EventCard;
