import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SecondaryHero from "../components/secondary-hero";
import EventCard from "../components/event-card";
import moment from "moment";

const EventsPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			events: allWpEvent(
				sort: { fields: eventFields___dateAndTime, order: ASC }
			) {
				nodes {
					title
					slug
					eventFields {
						dateAndTime
						description
						extraDate
						extraDateAndTime
						location
						heading
						mapLink
						subHeading
						endTime
						featuredImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										quality: 50
										placeholder: BLURRED
										layout: FULL_WIDTH
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const today = moment().startOf("day");

	const isEventUpcoming = (event) => {
		const startDate = moment(
			new Date(event.eventFields.dateAndTime.replace(" ", "T"))
		);
		const endDate = event.eventFields.extraDateAndTime
			? moment(new Date(event.eventFields.extraDateAndTime.replace(" ", "T")))
			: startDate;

		return endDate.isSameOrAfter(today);
	};

	const filteredEvents = data.events.nodes.filter(isEventUpcoming);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Events",
				item: {
					url: `${siteUrl}/events`,
					id: `${siteUrl}/events`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Events | Friends of Eastbourne Hospital"
				description="Latest Friends of Eastbourne Hospital events."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/events`,
					title: "Events | Friends of Eastbourne Hospital",
					description: "Latest Friends of Eastbourne Hospital events.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero title="Events" />
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row className="justify-content-center pt-lg-5">
							{filteredEvents.map((event) => (
								<EventCard key={event.slug} event={event} />
							))}
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default EventsPage;
